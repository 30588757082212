import React from 'react'
import '../styles/aboutme.css'

const AboutMe = () => {
  return (
    <div className='aboutme-container'>
        <h1 style={{fontSize: '50px'}}>Coming Soon</h1>
    </div>
  )
}

export default AboutMe